<script>
import FilterableTable from '@/components/general/FilterableTable'
export default {
  name: 'OtherServices',
  components: { FilterableTable },
  data () {
    return {
      tableHeaders: [
        { key: 'date', title: this.$t('transaction.history.table:column.1'), width: '120px' },
        { key: 'title', title: this.$t('transaction.history.table:column.2'), width: 'auto' },
        { key: 'value', title: this.$t('transaction.history.table:column.4'), width: '170px' }
      ],
      tableList: []
    }
  },
  created () {
    if (this.selectedWorkspace.region === 'BRA') {
      this.$store.dispatch('attempServicePayment').then(({ data }) => {
        this.tableList = data.map(service => ({
          title: service.description,
          date: this.formatDate(service.date),
          value: service.value
        }))
      })
      return
    }
    this.$router.push({ name: 'business.admin.center.payment.credits' })
    this.$store.dispatch('attemptSetFeedbackMsg', {
      type: 'error',
      title: this.$t('admin.center.payment:menu.error')
    })
  }
}
</script>
<template>
  <div class="other-services--container">
    <div class="other-services--payment-btn-wrapper">
      <v-btn class="btn transform-unset" :color="getPrimaryColor" dark @click="$router.push({ name: 'business.admin.center.payment.service.payment' })"><v-icon size="18px" color="#fff">mdi-currency-usd</v-icon>{{ $t('admin.center.payment:other.services.make.payment') }}</v-btn>
    </div>
    <div class="other-services--tip-wrapper">
      <v-icon size="24px" color="#2F6FED">mdi-information</v-icon>
      <div class="other-services--tip-text">
        <h5>{{ $t('admin.center.payment:other.services.tip.title') }}</h5>
        <p v-html="$t('admin.center.payment:other.services.tip.description', { href: 'http://www.google.com' })"></p>
      </div>
    </div>
    <filterable-table
      :title="$t('transaction.history.table:title')"
      :headers="tableHeaders"
      :items="tableList"
      emptyBoxSuffix="other.services"
    ></filterable-table>
  </div>
</template>
<style lang="scss">
.other-services--container {
  margin-top: -50px;
  .other-services--payment-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .other-services--tip-wrapper {
    display: flex;
    align-items: flex-start;
    background: linear-gradient(0deg, #F0F5FF, #F0F5FF), #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    .v-icon {
      margin-top: 4px;
      margin-right: 8px;
    }
    .other-services--tip-text {
      h5 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
      }
      p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #212121;
        margin-bottom: 0;
        a {
          color: rgba(47, 111, 237, 1);
        }
      }
    }
  }
}
</style>
